.CameraView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &--background {
    display: none;
  }

  > video {
    display: none;
    // width: 50vw;
    // height: 50vh;
    // position: absolute;
    // left: 0;
    // top: 0;
    object-fit: fill;
  }
  > canvas {
    // display: none;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
