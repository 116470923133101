.Setting {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .header {
    flex-direction: column;
    &_text {
      font-size: 30px;
      font-weight: 600;
      color: white;
    }
    &_error {
      font-size: 30px;
      font-weight: 600;
      margin-top: 30px;
      color: red;
    }
    &_inputbox {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      &_textfield {
        background-color: white;
        width: 45%;
      }
    }
    &_button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      &_save {
        width: 100%;
      }
    }
  }
}
