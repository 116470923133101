.Success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 50px;
  font-weight: 600;
  padding: 0 50px;
  color: white;
}
