.FormDemo {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .NoHuman {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: white;
    flex-direction: column;

    img {
      width: 250px;
    }

    h1 {
      font-size: 50px;
      margin: 50px 0;
    }
  }
  &__MaskIndicator {
    width: 200px;
    height: 100px;
    position: absolute;
    border-radius: 5px;
    background: #3498db;
    color: white;
    right: -200px;
    bottom: 50px;
    transition: right 0.2s linear;
    padding-left: 20px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--show {
      right: 0;
    }

    &--mask {
      background: lightgreen;
    }

    &--no-mask {
      background: palevioletred;
    }
  }
}
