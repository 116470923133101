.App-logo {
  height: 50px;
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mask-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  &-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    width: 70%;
    height: 70%;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.6);
    border: 5px solid white;
  }
}
