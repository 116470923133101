.middle {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: var(--MIDDLE_FONT_COLOR, white);
  flex-direction: column;

  img {
    width: 250px;
  }

  h1 {
    font-size: 50px;
    margin: 50px 0;
  }

  span {
    font-size: 30px;
  }

  .two_buttons {
    display: flex;
    justify-content: space-around;
    width: 60vw;
    margin-top: 30px;
    .button {
      font-size: 30px;
      width: 150px;
      background-color: var(--MIDDLE_CONTAINED_BUTTON_COLOR, #3f51b5);
      color: var(--MIDDLE_CONTAINED_BUTTON_TEXT_COLOR, white);
    }
    .outlined_button {
      background-color: var(--MIDDLE_OUTLINED_BUTTON_COLOR, white);
      color: var(--MIDDLE_OUTLINED_BUTTON_TEXT_COLOR, #3f51b5);
    }
  }

  .startButton {
    font-size: 40px;
    width: 200px;
    background-color: var(--MIDDLE_START_BUTTON_COLOR, #3f51b5);
    color: var(--MIDDLE_START_BUTTON_TEXT_COLOR, white);
  }
}
