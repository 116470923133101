.FaceManagementDialog {
  &__face {
    img {
      width: 100px !important;
    }
  }

  &__item {
    position: relative;
  }

  &__item-close {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: red !important;
    color: white !important;
    width: 20px;
    height: 20px;

    .MuiIcon-root {
      font-size: 12px !important;
    }
  }
}
