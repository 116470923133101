.FormView {
  height: 100vh;
  padding: 50px;
  color: white;

  &__choices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-button {
      width: 100%;
      height: 80px;
      background: transparent;
      border: 2px solid white;
      border-radius: 50px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      font-size: 30px;

      &--active {
        background: #3498db;
      }
    }
  }

  &__done {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100%;

    h2 {
      margin: 0;
    }
  }

  .MuiStepIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  .MuiStepLabel-label {
    font-size: 25px !important;
  }

  &__text {
    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid white !important;
      color: white !important;
      font-size: 30px;
      background-color: transparent;
      padding: 15px 20px;

      &:focus {
        outline: auto 5px -webkit-focus-ring-color;
      }
    }
  }

  .MuiStepper-horizontal {
    background-color: transparent !important;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: white !important;
    border: 1px solid transparent !important;
    border-radius: 0;

    .MuiStepIcon-text {
      fill: black !important;
    }
  }

  .MuiStepIcon-root {
    border: 1px solid white;
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: lightgreen;
  }

  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      color: white;
    }
  }
}
