.HumanSignup {
  height: 100vh;
  display: flex;
  justify-content: center;
  &__toggle {
    position: absolute !important;
    top: 20px;
    right: 20px;
  }

  &__faceDrawerToggle {
    position: absolute !important;
    bottom: 20px;
    right: 20px;
  }

  &__frame {
    width: 30vw;
    height: 30vw;
    border: 3px dashed white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1400px) {
      width: 35vw;
      height: 35vw;
    }
    @media screen and (max-width: 1000px) {
      width: 40vw;
      height: 40vw;
    }
    @media screen and (max-width: 800px) {
      width: 45vw;
      height: 45vw;
    }
    @media screen and (max-width: 600px) {
      width: 50vw;
      height: 50vw;
    }
    @media screen and (max-width: 400px) {
      width: 70vw;
      height: 70vw;
    }
  }

  &__countdown {
    position: absolute;
    // left: 50%;
    transform: translateY(-50%);
    bottom: -100px;
    font-size: 30px;
    color: white;
    &_number{
      font-size: 50px;
    }
  }

  &__result {
    position: absolute;
    //left: 50%;
    transform: translateY(-50%);
    top: 20vh;
    font-size: 30px;
    font-weight: 600;
    color: white;
    background-color: lightgrey;
  }
}
