.BodyCheck {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;

  .NoHuman {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: white;
    flex-direction: column;

    img {
      width: 250px;
    }

    h1 {
      font-size: 50px;
      margin: 50px 0;
    }
  }
  &__MaskIndicator {
    width: 200px;
    height: 100px;
    position: absolute;
    border-radius: 5px;
    background: #3498db;
    color: white;
    right: -200px;
    bottom: 50px;
    transition: right 0.2s linear;
    padding-left: 20px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--show {
      right: 0;
    }

    &--mask {
      background: lightgreen;
    }

    &--no-mask {
      background: palevioletred;
    }
  }

  .middle {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: white;
    flex-direction: column;
  }

  .MuiStepIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  .MuiStepLabel-label {
    font-size: 25px !important;
  }

  &__text {
    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid white !important;
      color: white !important;
      font-size: 30px;
      background-color: transparent;
      padding: 15px 20px;

      &:focus {
        outline: auto 5px -webkit-focus-ring-color;
      }
    }
  }

  .MuiStepper-horizontal {
    background-color: transparent !important;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: white !important;
    border: 1px solid transparent !important;
    border-radius: 0;

    .MuiStepIcon-text {
      fill: black !important;
    }
  }

  .MuiStepIcon-root {
    border: 1px solid white;
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: lightgreen;
  }

  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      color: white;
    }
  }
}

.Temperature {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;

  &__text {
    font-size: 16px;
  }

  &__value {
    font-size: 30px;
  }
}
