.FaceManager {
  padding: 20px;
  &__table {
    min-width: 300px !important;
    width: 600px !important;
    max-width: 100% !important;
  }

  &__item-image {
    width: 50px;
  }
}
